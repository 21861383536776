body {
  min-height: 100vh;
  background-image: linear-gradient(130deg, #0e1012, #1d4069);
}

.send-form .form-group {
  margin-bottom: 10px;
}

.send-form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.result {
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  text-align: center;
  color: wheat;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
}

.send-form input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  width: 200px;
}

.send-form button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #1e2128; /* Example button color */
  color: white;
}

/* Style for the option images */
.send-form img {
  vertical-align: middle;
  margin-right: 8px;
}

/* src/components/SendForm.module.css or src/components/SendForm.css */
.send-form {
  background: rgb(37,41,48);
  background: -moz-linear-gradient(90deg, rgba(37,41,48,1) 0%, rgba(26,47,80,1) 50%, rgba(37,41,48,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(37,41,48,1) 0%, rgba(26,47,80,1) 50%, rgba(37,41,48,1) 100%);
  background: linear-gradient(90deg, rgba(37,41,48,1) 0%, rgba(26,47,80,1) 50%, rgba(37,41,48,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#252930",endColorstr="#252930",GradientType=1);
  padding: 30px;
  border-radius: 20px;
  color: white;
  /* Adjust the width as necessary, may need to increase to fit both Amount and Asset side by side */
  width: 500px;
  margin: auto;
  border: 2px solid #3b3b3b
}

.send-form h2 {
  color: #fff;
}

.amount-asset-group {
  display: flex;
  justify-content: space-between;
  /* align-items: center; /* Align items vertically */
  gap: 10px;
}

.network-group {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Adjust the space between the form groups as needed */
}

.form-group {
  flex: 1;
}

.form-group.asset-select {
  /* width: 150px; /* Fixed width for the asset select box */
  margin-left: auto; /* Right align the asset select box */
}

/* Style for the option images with black text */
.send-form .react-select__option {
  color: black;
}

/* Style for the control of react-select to adjust width */
.send-form .react-select__control {
  width: 150px; /* Fixed width for the select control */
}

.wallet {
  margin-bottom: 10px;
}

.wallet p {
  font-weight: 700;
  margin: 5px 0 5px 0;
}

button {
  font-size: 18px;
  font-weight: bold;
  background-color:rgb(31, 68, 241)  !important;
}

hr {
  margin: 20px 0 20px 0;
  border: rgb(177, 176, 176) 1px dashed;
}

.innerBox {
  background: rgb(0,128,128);
  background: -moz-radial-gradient(circle, rgba(0,128,128,1) 0%, rgba(10,93,93,1) 75%, rgba(8,92,92,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(0,128,128,1) 0%, rgba(10,93,93,1) 75%, rgba(8,92,92,1) 100%);
  background: radial-gradient(circle, rgba(0,128,128,1) 0%, rgba(10,93,93,1) 75%, rgba(8,92,92,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008080",endColorstr="#085c5c",GradientType=1);
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.error {
  color:rgb(232, 150, 92);
  font-size: 20px;
  font-weight: bold;
  margin: 5px auto 15px auto;
  text-align: center;
}

/* ... rest of your styles ... */

.mainTop {
  margin: auto;
  color: #fff;
  font-size: 30px;
  text-align: center;
  padding-top: 20px;
}

.mainTop img:first-child {
  width: 50px;
  height: 50px;
  margin-bottom: -15px;
  border-radius: 50%;
}

.mainTop img {
  width: 40px;
  height: 40px;
  margin-bottom: -10px;
  border-radius: 50%;
}

.mainTop a {
  color: #fff;
  text-decoration: underline;
}

.mainTop p {
  font-size: 18px;
}

.mainTop p img {
  width: 35px;
  height: 35px;
}

.mainBox {
  min-height: 200px;
  overflow: auto;
  width: 100%;
  max-width: 600px;
  border: 3px solid #3a4856;
  background-image: linear-gradient(160deg, #2d3134, #254974);
  margin: 20px auto 5px auto;
  border-radius: 20px;
  padding: 5px 20px 20px 20px;
}

.mainBoxblack {
  overflow: auto;
  width: 100%;
  max-width: 600px;
  border: 3px solid #3a4856;
  background-color: #151922;
  margin: 20px auto 5px auto;
  border-radius: 20px;
  padding: 5px 20px 20px 20px;
  color: #fff;
  font-size: 20px;
}

.command {
  padding: 10px;
  border: 1px solid #4b4949;
  border-radius: 5px;
  background: #1a1a1a;
  font-size: 18px;
}

.oneBox {
  color: #fff;
  width:100%;
  display: inline-block;
  margin: auto;
}

.oneBox:first-child {
  padding-top:10px;
}

.oneBox .oneLeft {
  color: #fff;
  width:58%;
  display: inline-block;
  margin: 10px auto 10px auto;
  height: 120px;
  vertical-align: top;
}

.oneBox .oneRight {
  color: #fff;
  width:38%;
  display: inline-block;
  margin: 10px auto 10px auto;
  height: 120px;
  vertical-align: top;
}

.oneBox .oneLeft {
  border-right: 1px dashed #677584;
}

.oneBox .oneRight {
  padding: 0 0 0 15px;
}

.oneBox .oneLeftBottom {
  color: #fff;
  width:56%;
  display: inline-block;
  margin: 0 auto 10px auto;
  height: 120px;
  vertical-align: top;
}

.oneBox .oneRightBottom {
  color: #fff;
  width:100%;
  display: inline-block;
  margin: 0 auto 10px 10px;
  vertical-align: top;
}

.gleft {
  float: left;
  width: 200px;
}

.gright {
  float: right;
}

.gleft .top img, .gright .top img {
  width: 50px;
  height: 50px;
  margin: -2px 5px 0 0;
}

.gleft .bottom img {
  width: 22px;
  height: 22px;
  margin: -2px 5px 0 0;
}

.gright .bottom img {
  width: 30px;
  height: 30px;
  margin: -5px 5px 0 0;
}

.divFrom {
  margin: 10px 10px 0 0;
  background-color: #54606b;
  border-radius: 10px;
  height: 50px;
  padding: 10px;
  cursor: pointer;
}

.divTo {
  margin: 10px 10px 20px 0;
  background-color: #54606b;
  border-radius: 10px;
  height: 50px;
  padding: 10px;
}

.divFrom img {
  float: left;
  width: 50px;
  height: 50px;
  border: 1px solid #677584;
  border-radius: 50%;
}

.divTo img {
  float: left;
  width: 50px;
  height: 50px;
  border: 1px solid #677584;
  border-radius: 50%;
}

.top {
  margin-left: 0px;
  margin: 0 0 0 0;
  padding-bottom: 3px;
  font-size: 18px;
}

.bottom {
  margin-left: 0px;
  margin: 5px 0 0 0;
  padding: 0px;
  font-size: 15px;
}

.gright .top {
  padding-left: 0px !important;
  font-size: 15px !important;
}

.gright .bottom {
  font-size: 20px !important;
}

.mainBox hr {
  height: 0px;
  border-color:#4c5967;
}

.inputClass {
  margin: 10px 0 0 10px;
  height: 25px;
  background-color: #54606b;
  border-style: dashed;
  border-width: 0 0 1px 0;
  border-color: #9ba3ab;
  color: #fff;
  font-size: 20px;
  width:90%
}

.inputClass:focus {
  outline: none;
}

.oneRightBottom span {
  margin-left:10px;
}

.mainBox button {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  background-color: rgb(31, 68, 241) !important;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.accDetails {
  color: #fff;
  font-weight: bold;
}

.accDetails span {
  margin: 0 5px 0 0;
  color:#9ba3ab;
}

.balances {
  margin: 10px 0 20px 0;
  padding: 0 0 0 5px;
  border-left: 3px solid #677584;
}

.balances p {
  margin: 5px;
}

.feeSection {
  border-top: 1px solid #677584;
  border-bottom: 1px solid #677584;
  margin-bottom: 20px;
  padding: 10px 0 10px 0;
}

.miniBox {
  display: block;
}

.miniBox p {
  display: inline-block;
  color: #80e093;
  width: 48%;
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  font-weight: bold;
  padding: 3px;
}

.right {
  text-align: right;
}

.progress {
  color: #9baba5;
  font-size: 15px;
  font-weight: bold;
}

.txComplete {
  text-align: center;
  font-size: 25px;
  border-top: 1px solid #677584;
  border-bottom: 1px solid #677584;
  padding: 10px;
  color: #80e093;
}

.progress p a {
  color:rgb(126, 226, 246);
  text-decoration: none;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); } /* Safari and Chrome */
  100% { -webkit-transform: rotate(360deg); }
}


.loader2 {
  border: 3px solid #dce0f2;
  border-radius: 50%;
  border-top: 3px solid #949494;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  padding: 0px;
  margin-left: 10px;
  margin-bottom: -5px;
  margin-right: 5px;
  display: inline-block;
}

.tick-mark img {
  width: 20px;
  height: 20px;
  margin-bottom: -4px;
  padding-right: 2px;
}

.myLink {
  display: inline-block;
  font-size: 18px;
  color: floralwhite;
}

.myLink a {
  color: aqua;
}

.powered {
  text-align: center;
  color:#4d5d6e;
  margin: 0px;
  padding: 0 0 20px 0;
}

